import { OneOneColor } from '@pafcloud/style';

export const Colors = {
  Text: OneOneColor.White,
  DepositQuickButtons: undefined,
  DepositQuickButtonsHover: undefined,
  Border: undefined,
  Background: OneOneColor.Background,
  SidebarContent: undefined,
  SidebarTopBackground: undefined,
  SidebarOverlay: `${OneOneColor.Black}b0`,
  EmphasizedLink: undefined,
};
