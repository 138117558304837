import { OneOneColor } from '@pafcloud/style';

export const Colors = {
  Text: OneOneColor.White,
  TextHover: OneOneColor.Primary,
  TextActive: OneOneColor.Black,
  TextShadow: undefined,
  TextTransform: undefined,

  Icon: OneOneColor.Neutral,
  IconActive: 'currentColor',

  Background: undefined,
  BackgroundHover: undefined,
  BackgroundActive: OneOneColor.Primary,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: OneOneColor.Darker,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 600,
  WeightHover: 600,
  WeightActive: 900,
} as const;
