import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';
import { Color as OneOneColor } from './color.momentum';

const SpeedyBetEsBrandColor = {
  Midnight: '#091417',

  // Primary Colors
  Primary: '#00f8a3',
  PrimaryDarker: '#15564a',
  //Alternate Colors
  Alternate: '#F80055',

  // Gradients
  GradientStart: '#00f8a3',
  GradientEnd: '#b0ffe4',

  Black: '#000B0A',
  Darker: '#0c2423',
  Dark: '#2d3d3d',
  Light: '#84a5a2',
  Lightest: '#cedbda',
  White: '#ffffff',

  // Extra
  Green: '#00f8a3',
  Yellow: '#F8A300',
  Error: '#ff3333',
};

const Primitive: PrimitiveColorType = {
  Primary: SpeedyBetEsBrandColor.Primary,
  PrimaryTint: SpeedyBetEsBrandColor.GradientEnd,
  PrimaryContrast: SpeedyBetEsBrandColor.Black,

  Secondary: SpeedyBetEsBrandColor.White,
  SecondaryTint: SpeedyBetEsBrandColor.GradientEnd,
  SecondaryContrast: SpeedyBetEsBrandColor.Darker,

  Accent: SpeedyBetEsBrandColor.Primary,
  AccentTint: SpeedyBetEsBrandColor.GradientEnd,
  AccentContrast: SpeedyBetEsBrandColor.Black,

  Gradient: SpeedyBetEsBrandColor.Primary,
  GradientTint: SpeedyBetEsBrandColor.GradientEnd,
  GradientContrast: SpeedyBetEsBrandColor.Black,
};

const TextColors: TextColorType = {
  HeadingText: SpeedyBetEsBrandColor.White,
  BodyText: SpeedyBetEsBrandColor.White,
  MutedText: SpeedyBetEsBrandColor.Light,
  HighlightedText: SpeedyBetEsBrandColor.White,
  LinkText: SpeedyBetEsBrandColor.Green,
  ErrorText: SpeedyBetEsBrandColor.Yellow,
  DisabledText: SpeedyBetEsBrandColor.Dark,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: SpeedyBetEsBrandColor.Black,
    Foreground: SpeedyBetEsBrandColor.Lightest,
    Dimmed: createTransparentColor(SpeedyBetEsBrandColor.Lightest, 0.25),
  },
  Nested: {
    Background: SpeedyBetEsBrandColor.Darker,
    Foreground: SpeedyBetEsBrandColor.Lightest,
    Dimmed: createTransparentColor(SpeedyBetEsBrandColor.Primary, 0.15),
  },
  Disabled: {
    Background: SpeedyBetEsBrandColor.Dark,
    Foreground: SpeedyBetEsBrandColor.Light + '80',
    Dimmed: createTransparentColor(SpeedyBetEsBrandColor.Darker, 0.15),
  },
  Floating: {
    Background: SpeedyBetEsBrandColor.Midnight,
    Foreground: SpeedyBetEsBrandColor.Lightest,
    Dimmed: createTransparentColor(SpeedyBetEsBrandColor.Lightest, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: SpeedyBetEsBrandColor.Green,
  SuccessContrast: SpeedyBetEsBrandColor.Darker,

  Info: SpeedyBetEsBrandColor.GradientEnd,
  InfoContrast: SpeedyBetEsBrandColor.PrimaryDarker,

  Attention: SpeedyBetEsBrandColor.Yellow,
  AttentionContrast: SpeedyBetEsBrandColor.Darker,

  Danger: SpeedyBetEsBrandColor.Error,
  DangerContrast: SpeedyBetEsBrandColor.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '16px',
};

const Elevation: ElevationType = {
  Level1: '0 1px 2px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: SpeedyBetEsBrandColor.Primary,
  BonusContrast: SpeedyBetEsBrandColor.Black,
};

const Layout: LayoutColors = {
  Background: SpeedyBetEsBrandColor.Midnight,
  BrandBorder: Primitive.Primary,
  BrowserTheme: SpeedyBetEsBrandColor.Midnight,
  LoadingColor: Primitive.Primary,
  Overlay: SpeedyBetEsBrandColor.Black + 'CC',
  Sidebar: {
    Background: SpeedyBetEsBrandColor.Black,
    Foreground: SpeedyBetEsBrandColor.Lightest,
  },
};

const Hero: HeroColorType = {
  Heading: SpeedyBetEsBrandColor.White,
  Text: SpeedyBetEsBrandColor.White,
  TextHighlight: Primitive.Primary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color = {
  ...OneOneColor, // TODO: Delete this.
  ...SpeedyBetEsBrandColor,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: SpeedyBetEsBrandColor.Midnight,
  CardBackground: SpeedyBetEsBrandColor.Darker,

  // Dummy color to make ignore type errors.
  FocusHighlight: SpeedyBetEsBrandColor.Primary,

  // Shadows, highlight and transparency
  ShadowInset: createTransparentColor(SpeedyBetEsBrandColor.Light, 0.2),

  // Transparent colors
  DarkerTransparent69: createTransparentColor(SpeedyBetEsBrandColor.Darker, 0.69),

  LightTransparent90: createTransparentColor(SpeedyBetEsBrandColor.Light, 0.9),
  LightTransparent20: createTransparentColor(SpeedyBetEsBrandColor.Light, 0.2),
  PrimaryTransparent40: createTransparentColor(SpeedyBetEsBrandColor.Primary, 0.4),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart}, ${ColorEnd})`,
  AdaptiveBackground: `linear-gradient(86deg, ${SpeedyBetEsBrandColor.GradientStart}, ${SpeedyBetEsBrandColor.GradientEnd}) border-box`,

  // Theme color for mobile browsers
  BrowserTheme: SpeedyBetEsBrandColor.Primary,

  // Validation and notification colors
  Success: SpeedyBetEsBrandColor.Primary,
  Warning: SpeedyBetEsBrandColor.Yellow,
  Error: SpeedyBetEsBrandColor.Error,
};
